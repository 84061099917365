import { MobileCard } from "./MobileCard";
import { MobileCardHeader } from "./MobileCardHeader";
import { MobileCardBody } from "./MobileCardBody";
import { MobileCardFooter } from "./MobileCardFooter";

export {
    MobileCard,
    MobileCardHeader,
    MobileCardBody,
    MobileCardFooter,
};