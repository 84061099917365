import axios from 'axios';
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import { 
    ArrowRightIcon,
    ArrowLeftIcon,
} from '@heroicons/react/24/outline';
import { ApplicationState } from "../../models/ApplicationState";
import { BillingInfo } from "../../models/BillingInfo";
import { MobileContainer } from '../../tailwind/Containers';
import {
    MobileCard,
    MobileCardHeader,
    MobileCardBody,
    MobileCardFooter,
} from '../../tailwind/Panels';
import { Patient, Dose, Doses } from '../PatientInfo';

const SQUARE_APPLICATION_ID = "sq0idp-77z6Dn2Qpc8ae4iRcpginQ";
const SQUARE_LOCATION_ID = "LZG869C5V0EGT"; 

interface CheckoutProps {
    billingState: BillingInfo;
    setBillingState: React.Dispatch<React.SetStateAction<BillingInfo>>;
    setApplicationState: React.Dispatch<React.SetStateAction<ApplicationState>>;
    patients: Patient[];
    paymentAmount: number;
}


function CheckoutComponent({
    billingState,
    setApplicationState,
    patients,
    paymentAmount,
}: CheckoutProps) {

    async function createPayment(details: any, token: any) {

        // Call the backend API and submit the amount/details.token
        axios.post((window.location.origin + "/api/payments"), {
            source_id: details.token,
            payment_amount: paymentAmount,
            first_name: billingState.first_name,
            last_name: billingState.last_name,
            billing_email: billingState.billing_email,
            address_line_1: billingState.address_line_1 ? billingState.address_line_1 : "",
            address_line_2: billingState.address_line_2,
            city: billingState.city,
            zip: billingState.zip,
            flu_dose: patients.reduce((total, patient) => {
                if(patient.dose === 'Fluzone Quadrivalent') {
                    return (total + 1);
                }
                return (total);
            }, 0),
            flu_dose_hd: patients.reduce((total, patient) => {
                if(patient.dose === 'Fluzone High-Dose (65+ years only)') {
                    return (total + 1);
                }
                return (total);
            }, 0)
        }).then((result: any) => {
            setApplicationState(ApplicationState.RECEIPT);
        }).catch((error: any) => {
            console.log(error);
        });

    };

    return(<>
        <MobileContainer>
            <MobileCard>

                <MobileCardHeader>
                    <div className="grid grid-cols-1 sm:grid-cols-2">
                        <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Step 3: Checkout</h3>
                        </div>
                    </div>
                </MobileCardHeader>

                <MobileCardBody>
                    <PaymentForm 
                        applicationId={SQUARE_APPLICATION_ID} 
                        locationId={SQUARE_LOCATION_ID}
                        cardTokenizeResponseReceived={createPayment}

                        // Payment Details
                        createVerificationDetails={() => {

                            console.log(billingState);

                            return({
                                amount: paymentAmount.toString(),

                                // Billing Information
                                billingContact: {
                                    addressLines: [
                                        billingState.address_line_1, 
                                        billingState.address_line_2 || "",
                                    ],
                                    familyName: billingState.last_name,
                                    givenName: billingState.first_name,
                                    countryCode: 'US',
                                    city: billingState.city,
                                    zipCode: billingState.zip,
                                },

                                // Misc details
                                currencyCode: 'USD',
                                intent: 'CHARGE',
                            });
                        }}
                    >
                        <CreditCard>Pay ${paymentAmount}</CreditCard>
                    </PaymentForm>
                </MobileCardBody>

                <MobileCardFooter>
                    <div className='grid grid-cols-1 gap-x-4 md:grid-cols-8'>
                        {/* Back button */}
                        <div className='col-span-1 md:col-span-3 flex'>
                            <button type="button"
                                    className="inline-flex justify-center rounded-full border border-transparent bg-red-600
                                    p-2 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2
                                    focus:ring-red-500 focus:ring-offset-2 min-w-full sm:min-w-fit sm:mt-0"
                                    onClick={() => {setApplicationState(ApplicationState.BILLING_INFO)}}>
                                <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
                                <span className="mx-3 text-base">Go Back</span>
                            </button>
                        </div>
                    </div>
                </MobileCardFooter>

            </MobileCard>
        </MobileContainer>
    </>);
}

export {CheckoutComponent};