import { Fragment } from "react";

export function MobileCard({ children }: { children: React.ReactNode }) {
    return (<Fragment>

        {/* Card, edge-to-edge on mobile */}
        {/* Be sure to use this with a layout container that is full-width on mobile */}
        <div className="mt-5 overflow-hidden bg-white shadow sm:rounded-lg">
            {children}
        </div>

    </Fragment>);
}