import { useState, Fragment } from "react";

interface InputProps {
    id: string;
    name: string;
    type: string;
    placeholder: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    valid: boolean;
}

export function Input({ 
    id, 
    name, 
    type, 
    placeholder, 
    value, 
    onChange,
    valid,
}: InputProps){

    // Concatenates the classes passed in and returns them.
    const classNames = (...classes: string[]) => {
        return classes.filter(Boolean).join(' ')
    }

    // Returns the input component classes.
    const inputClasses = () => {

        // Input base classes
        const baseClasses = classNames(
            "block w-full", // Position & Sizing
            "rounded-md shadow-sm sm:text-sm" // Styling
        );

        // No warrning or error classes
        const nweClasses = classNames(
            "border-gray-300 ", // Coloring
            "focus:border-indigo-500 focus:ring-indigo-500", // Focus
        );

        // Input error classes
        const errorClasses = classNames(
            "border-red-500", // Coloring
            "focus:border-red-500 focus:ring-red-500", // Focus
        );

        return classNames(
            baseClasses,
            valid ? nweClasses : errorClasses,
        );
    };

    return(<Fragment>

        <div className="mt-1">
            <input
                id={id}
                type={type}
                name={name}
                placeholder={placeholder}
                value={value} 
                onChange={(event) => {
                    onChange(event);
                }}
                className={inputClasses()}
            />
            {valid ? null : (
                <div className="pt-1 text-xs text-red-500">This is a required field!</div>
            )}
        </div>

    </Fragment>);
}