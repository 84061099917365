import { Fragment } from "react";

export function SelectGroup({ children }: { children: React.ReactNode }) {
    return(<Fragment>

        <div>
            {children}
        </div>

    </Fragment>);
}