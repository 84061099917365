import { Fragment } from "react";

export function SelectLabel({ htmlFor, children }: { htmlFor: string, children: React.ReactNode }) {
    return(<Fragment>

        <label htmlFor={htmlFor} className="block text-sm font-medium text-gray-700">
            {children}
        </label>

    </Fragment>);
}