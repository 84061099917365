import { Fragment } from "react";

export function Select(
    { id, name, defaultValue, options, value, onChange, valid }:
    { id: string, name: string, defaultValue: string, options: string[], value: any, onChange: any, valid: boolean }
) {

    // Concatenates the classes passed in and returns them.
    const classNames = (...classes: string[]) => {
        return classes.filter(Boolean).join(' ')
    }

    // Returns the select component classes.
    const selectClasses = () => {

        // Input base classes
        const baseClasses = classNames(
            "mt-1 py-2 pl-3 pr-10 block w-full", // Position & Sizing
            "text-base rounded-md shadow-sm sm:text-sm focus:outline-none" // Styling
        );

        // No warrning or error classes
        const nweClasses = classNames(
            "border-gray-300 ", // Coloring
            "focus:border-indigo-500 focus:ring-indigo-500", // Focus
        );

        // Input error classes
        const errorClasses = classNames(
            "border-red-500", // Coloring
            "focus:border-red-500 focus:ring-red-500", // Focus
        );

        return classNames(
            baseClasses,
            valid ? nweClasses : errorClasses,
        );
    };

    return(<Fragment>

        <select id={id} name={name} defaultValue={defaultValue} value={value} 
                onChange={(event) => {onChange(event);}}
                className={selectClasses()}>
            {options.map((option) => (<option>{option}</option>))}
        </select>
        {valid ? null : (
            <div className="pt-1 text-xs text-red-500">Please select a dose!</div>
        )}

    </Fragment>);
}