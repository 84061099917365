import { Fragment, useState } from "react";
import { 
    PlusIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
    XMarkIcon
} from '@heroicons/react/24/outline';

import { BillingInfo } from "../../models/BillingInfo";
import { ApplicationState } from "../../models/ApplicationState";
import { MobileContainer } from '../../tailwind/Containers';    
import { 
  MobileCard,
  MobileCardHeader,
  MobileCardBody,
  MobileCardFooter,
} from '../../tailwind/Panels';

import {
  Input,
  InputGroup,
  InputLabel,
} from '../../tailwind/Forms/InputGroups';

import {
  Select,
  SelectGroup,
  SelectLabel,
} from '../../tailwind/Forms/SelectMenus';

// Create price formatter
var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

// Patient Interface
interface Patient {
    firstName: string;
    lastName: string;
    dose: string;
}

// Dose Interface
interface Dose {
    name: string;
    price: number;
}

// Dose Options
const Doses: Dose[] = [
    {'name': '...', 'price': 0.00},
    {'name': 'Fluzone Quadrivalent', 'price': 45.00},
    {'name': 'Fluzone High-Dose (65+ years only)', 'price': 90.00},
];

// Formatted Dose Options
const doseOptions: string[] = Doses.map((option) => {
    return(option.name);
});

function PatientComponent({index, isSubmitted, patientsState, setPatientsState}: any) {

    return(<Fragment>

        {/* Patient Component */}
        <div className='mb-2 grid grid-cols-1 gap-y-2 gap-x-2 sm:grid-cols-8 bg-gray-200 sm:bg-gray-50 hover:bg-gray-200 p-2 rounded'>

            {/* Input: Patient First Name */}
            <div className='sm:col-span-2'>
                <InputGroup>
                    <InputLabel htmlFor="patientsFirstName">First Name:</InputLabel>
                    <Input id="patientsFirstName" type="text" name="Patient's First Name" placeholder="John" 
                            value={patientsState[index].firstName} 
                            valid={isSubmitted ? patientsState[index].firstName != undefined && 
                                   patientsState[index].firstName != "" : true}
                            onChange={(event: any) => {
                                // Update the patients state when the input changes
                                const newPatientsState = [...patientsState];
                                newPatientsState[index].firstName = event.target.value;
                                setPatientsState(newPatientsState);
                            }}/>
                </InputGroup>
            </div>

            {/* Input: Patient First Name */}
            <div className='sm:col-span-2'>
                <InputGroup>
                    <InputLabel htmlFor="patientsLastName">Last Name:</InputLabel>
                    <Input id="patientsLastName" type="text" name="Patient's Last Name" placeholder="Doe" 
                            value={patientsState[index].lastName} 
                            valid={isSubmitted ? patientsState[index].lastName != undefined &&
                                   patientsState[index].lastName != '' : true}
                            onChange={(event: any) => {
                                // Update the patients state when the input changes
                                const newPatientsState = [...patientsState];
                                newPatientsState[index].lastName = event.target.value;
                                setPatientsState(newPatientsState);
                            }}/>
                </InputGroup>
            </div>

            {/* Input: Patients Flu Dose */}
            <div className='sm:col-span-2'>
                <SelectGroup>
                    <SelectLabel htmlFor="vaccineAdministered">Flu Dose:</SelectLabel>
                    <Select id="vaccineAdministered" name="Vaccine Administered" options={doseOptions} defaultValue="..." 
                                value={patientsState[index].dose}
                                valid={isSubmitted ? patientsState[index].dose != undefined && 
                                       patientsState[index].dose != '' &&
                                       patientsState[index].dose != '...' : true}
                                onChange={
                                    (event: any) => {
                                        // Update the patients state when the input changes
                                        const newPatientsState = [...patientsState];
                                        newPatientsState[index].dose = event.target.value;
                                        setPatientsState(newPatientsState);
                                    }
                                }/>
                </SelectGroup>
            </div>

            {/* Dose Price */}
            <div className='sm:col-span-1 flex items-end justify-end pb-2 mt-3'>
                <span className="mr-3 block sm:hidden">Price:</span>
                {formatter.format(Doses.find((dose) => dose.name === patientsState[index].dose)?.price || 0)}
            </div>

            {/* Manage Patients */}
            <div className='sm:col-span-1 flex justify-center mb-3 sm:mb-0'>

                {/* Delete Patient Button */}
                <div className="w-1/2 flex justify-center items-center mt-0 sm:mt-4">
                    {index > 0 ? (<>
                        <button type='button' onClick={() => {
                            // Remove patient from state
                            setPatientsState(patientsState.filter((patient: Patient, i: number) => i !== index));
                        }} className="flex">
                            <span className="block sm:hidden mr-3">Delete Patient</span>
                            <XMarkIcon className='h-6 w-6 text-red-600' aria-hidden='true' />
                        </button>
                    </>): null}
                </div>

                {/* Add Patient Button */}
                <div className="w-1/2 flex justify-center items-center mt-0 sm:mt-4">
                    {index == patientsState.length - 1 ? (
                        <button type='button' onClick={() => {
                            // Add patient to state
                            setPatientsState([...patientsState, {'fullName': '', 'dose': '...'}]);
                        }} className="flex">
                            <span className="block sm:hidden mr-3">Add Patient</span>
                            <PlusIcon className='h-6 w-6 text-indigo-600' aria-hidden='true' />
                        </button>
                    ): null}
                </div>

            </div>
        </div>

    </Fragment>);
}


interface PatientInfoComponentProps {
    patients: Patient[];
    setPatients: React.Dispatch<React.SetStateAction<Patient[]>>;
    setPaymentAmount: React.Dispatch<React.SetStateAction<number>>;
    setApplicationState: React.Dispatch<React.SetStateAction<ApplicationState>>;
}


function PatientInfoComponent({
    patients, setPatients,
    setPaymentAmount,
    setApplicationState
}: PatientInfoComponentProps) {

    const [isSubmitted, setIsSubmitted] = useState(false);

    return(<Fragment>

        <MobileContainer>
            <MobileCard>

                <MobileCardHeader>
                    <div className="grid grid-cols-1 sm:grid-cols-2">
                        <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Step 1: Patient(s) Information</h3>
                        </div>
                    </div>
                </MobileCardHeader>

                <MobileCardBody>

                    <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-8'>

                        {/* Empty Spacer */}
                        <div className='hidden sm:block sm:col-span-6'></div>

                        {/* Total Price */}
                        <div className='hidden sm:flex col-span-1 items-end'>
                            <div className="text-center underline underline-offset-3 w-full">Prices</div>
                        </div>

                        {/* Empty Spacer */}
                        <div className='hidden sm:flex col-span-1 items-end'>
                            <div className="text-center underline underline-offset-3 w-full">Add / Remove</div>
                        </div>

                    </div>

                    {/* Render Patients */}
                    {patients.map((_, index) => {
                            return(
                            <PatientComponent 
                                index={index}
                                patientsState={patients}
                                setPatientsState={setPatients}
                                isSubmitted={isSubmitted}
                            />);
                        })
                    }

                    <div className='mt-8 grid grid-cols-1 gap-y-2 gap-x-2 sm:grid-cols-8 p-2'>

                        {/* Empty Spacer */}
                        <div className='hidden sm:block sm:col-span-4'></div>

                        {/* Total Price */}
                        <div className='hidden sm:block col-span-1 sm:col-span-2 flex items-end justify-end pb-2 text-end'>
                            Total:
                        </div>

                        {/* Dose Price */}
                        <div className='col-span-1 sm:col-span-1 flex items-end justify-end pb-2'>
                            <span className="mr-3 block sm:hidden">Total Price:</span>
                            {
                                formatter.format(
                                    patients.reduce((total, patient) => {
                                        let _paymentAmount = total + (Doses.find((dose) => dose.name === patient.dose)?.price || 0);
                                        setPaymentAmount(_paymentAmount);
                                        return(_paymentAmount);
                                    }, 0)
                                )
                            }
                        </div>

                        {/* Empty Spacer */}
                        <div className='hidden sm:block sm:col-span-1'></div>

                    </div>

                </MobileCardBody>

                <MobileCardFooter>
                    <div className='grid grid-cols-1 gap-x-4 md:grid-cols-8'>

                        {/* Next Step Button */}
                        <div className='col-span-1 md:col-start-7 md:col-span-2 flex justify-end'>
                            <button type="button"
                                    className="inline-flex justify-center rounded-full border border-transparent bg-green-600
                                    p-2 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2
                                    focus:ring-indigo-500 focus:ring-offset-2 min-w-full sm:min-w-fit sm:mt-0"
                                    onClick={() => {

                                        // Set isSubmitted to true
                                        setIsSubmitted(true);

                                        // Validate inputs
                                        let isValid = patients.reduce((valid: boolean, patient: Patient) => {
                                            console.log(patient);
                                            console.log(patient.lastName === '');

                                            //let _paymentAmount = total + (Doses.find((dose) => dose.name === patient.dose)?.price || 0);
                                            if (patient.firstName == undefined || patient.firstName === '' ||
                                                    patient.lastName == undefined || patient.lastName === '' ||
                                                    patient.dose == undefined || patient.dose === '...') {
                                                return(false);
                                            }
                                            return(valid);
                                        }, true);

                                        // Change the state if valid
                                        if (isValid) {
                                            setApplicationState(ApplicationState.BILLING_INFO)
                                        };
                                    }}>
                                <span className="mx-3 text-base">Next Step</span>
                                <ArrowRightIcon className="h-6 w-6 mr-3" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </MobileCardFooter>

            </MobileCard>
        </MobileContainer>

    </Fragment>);
}

export { PatientInfoComponent, Doses };
export type { Patient, Dose };