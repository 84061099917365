import { 
    ArrowRightIcon,
    ArrowLeftIcon,
} from '@heroicons/react/24/outline';
import { ApplicationState } from '../../models/ApplicationState';
import { BillingInfo } from "../../models/BillingInfo";
import { MobileContainer } from '../../tailwind/Containers';    
import { 
    MobileCard,
    MobileCardHeader,
    MobileCardBody,
    MobileCardFooter,
} from '../../tailwind/Panels';

import {
    Input,
    InputGroup,
    InputLabel,
} from '../../tailwind/Forms/InputGroups';

import { useState } from 'react';

interface BillingProps {
    billingState: BillingInfo;
    setBillingState: React.Dispatch<React.SetStateAction<BillingInfo>>;
    setApplicationState: React.Dispatch<React.SetStateAction<ApplicationState>>;
}

function BillingInfoComponent({
    billingState, setBillingState,
    setApplicationState,
}: BillingProps) {

    const [isSubmitted, setIsSubmitted] = useState(false);

    return(<>
        <MobileContainer>
            <MobileCard>

                <MobileCardHeader>
                    <div className="grid grid-cols-1 sm:grid-cols-2">
                        <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Step 2: Billing Information</h3>
                        </div>
                    </div>
                </MobileCardHeader>

                <MobileCardBody>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 p-2">
                        <div className="col-span-1">
                            <InputGroup>
                                <InputLabel htmlFor="billingInfoFirstName">First Name:</InputLabel>
                                <Input id="billingInfoFirstName" type="text" name="Billing Info First Name" placeholder="John" 
                                        value={billingState.first_name} 
                                        valid={!isSubmitted ? true : billingState.first_name != undefined}
                                        onChange={(event: any) => {
                                            // Update the billingInfo state
                                            setBillingState(billingState => ({...billingState, first_name: event.target.value}));
                                        }}/>
                            </InputGroup>
                        </div>
                        <div className="col-span-1">
                            <InputGroup>
                                <InputLabel htmlFor="patientsLastName">Last Name:</InputLabel>
                                <Input id="patientsLastName" type="text" name="Patient's Last Name" placeholder="Doe" 
                                        value={billingState.last_name}
                                        valid={!isSubmitted ? true : billingState.last_name != undefined}
                                        onChange={(event: any) => {
                                            // Update the billingState state
                                            setBillingState(billingState => ({...billingState, last_name: event.target.value}));
                                        }}/>
                            </InputGroup>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 p-2">
                        <div className="col-span-2">
                            <InputGroup>
                                <InputLabel htmlFor="addressLine1">Address Line 1:</InputLabel>
                                <Input id="addressLine1" type="text" name="Address Line 1" placeholder="123 Main St."
                                        value={billingState.address_line_1}
                                        valid={!isSubmitted ? true : billingState.address_line_1 != undefined}
                                        onChange={(event: any) => {
                                            // Update the billingInfo state
                                            setBillingState(billingState => ({...billingState, address_line_1: event.target.value}));
                                        }}/>
                            </InputGroup>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 p-2">
                        <div className="col-span-2">
                            <InputGroup>
                                <InputLabel htmlFor="addressLine1">Address Line 2:</InputLabel>
                                <Input id="addressLine1" type="text" name="Address Line 1" placeholder="Suite 123"
                                        value={billingState.address_line_2}
                                        valid={true}
                                        onChange={(event: any) => {
                                            // Update the billingInfo state
                                            setBillingState(billingState => ({...billingState, address_line_2: event.target.value}));
                                        }}/>
                            </InputGroup>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-3 p-2">
                        <div className="col-span-1">
                            <InputGroup>
                                <InputLabel htmlFor="billingInfoCity">City:</InputLabel>
                                <Input id="billingInfoCity" type="text" name="Billing Info City" placeholder="Round Rock" 
                                        value={billingState.city}
                                        valid={!isSubmitted ? true : billingState.city != undefined}
                                        onChange={(event: any) => {
                                            // Update the billingInfo state
                                            setBillingState(billingState => ({...billingState, city: event.target.value}));
                                        }}/>
                            </InputGroup>
                        </div>
                        <div className="col-span-1">
                            <InputGroup>
                                <InputLabel htmlFor="billingInfoZip">Zipcode:</InputLabel>
                                <Input id="billingInfoZip" type="text" name="Billing Info Zipcode" placeholder="12345" 
                                        value={billingState.zip}
                                        valid={!isSubmitted ? true : billingState.zip != undefined}
                                        onChange={(event: any) => {
                                            // Update the billingInfo state
                                            setBillingState(billingState => ({...billingState, zip: event.target.value}));
                                        }}/>
                            </InputGroup>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-3 p-2">
                        <div className="col-span-2">
                            <InputGroup>
                                <InputLabel htmlFor="billingInfoEmail">Email:</InputLabel>
                                <Input id="billingInfoEmail" type="text" name="Billing Info Email" placeholder="john.doe@yourdomain.com" 
                                        value={billingState.billing_email}
                                        valid={!isSubmitted ? true : billingState.billing_email != undefined}
                                        onChange={(event: any) => {
                                            // Update the billingInfo state
                                            setBillingState(billingState => ({...billingState, billing_email: event.target.value}));
                                        }}/>
                            </InputGroup>
                        </div>
                    </div>

                </MobileCardBody>

                <MobileCardFooter>
                    <div className='grid grid-cols-1 gap-x-4 md:grid-cols-8'>
                        {/* Back button */}
                        <div className='col-span-1 md:col-span-3 flex'>
                            <button type="button"
                                    className="inline-flex justify-center rounded-full border border-transparent bg-red-600
                                    p-2 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2
                                    focus:ring-red-500 focus:ring-offset-2 min-w-full sm:min-w-fit sm:mt-0"
                                    onClick={() => {setApplicationState(ApplicationState.PATIENT_INFO)}}>
                                <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
                                <span className="mx-3 text-base">Go Back</span>
                            </button>
                        </div>

                        {/* Next Button */}
                        <div className='col-span-1 md:col-start-7 md:col-span-2 flex justify-end'>
                            <button type="button"
                                    className="inline-flex justify-center rounded-full border border-transparent bg-green-600
                                    p-2 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2
                                    focus:ring-indigo-500 focus:ring-offset-2 min-w-full sm:min-w-fit sm:mt-0"
                                    onClick={() => {
                                        // Change the submission state
                                        setIsSubmitted(true);

                                        // Validate the inputs
                                        if (billingState.first_name !== undefined && billingState.last_name != undefined &&
                                            billingState.address_line_1 != undefined && billingState.city != undefined && 
                                            billingState.zip != undefined && billingState.billing_email != undefined) {

                                                console.log(billingState.first_name);

                                                // Update the application state
                                                setApplicationState(ApplicationState.CHECKOUT);
                                        };
                                    }}>
                                <span className="mx-3 text-base">Next Step</span>
                                <ArrowRightIcon className="h-6 w-6 mr-3" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </MobileCardFooter>

            </MobileCard>
        </MobileContainer>
    </>);
}

export { BillingInfoComponent };