import { Patient } from '../PatientInfo';
import { BillingInfo } from '../../models/BillingInfo';
import { MobileContainer } from '../../tailwind/Containers';    
import { 
    MobileCard,
    MobileCardHeader,
    MobileCardBody,
    MobileCardFooter,
} from '../../tailwind/Panels';

// Create price formatter
var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

interface ReceiptProps {
    patients: Patient[];
    paymentAmount: number;
    billingState: BillingInfo;
}

function ReceiptComponent({ 
    patients,
    paymentAmount,
    billingState,
}: ReceiptProps) {
    return(<>
        <MobileContainer>
            <MobileCard>

                <MobileCardHeader>
                    <div className="grid grid-cols-1 sm:grid-cols-2">
                        <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Step 4: Receipt</h3>
                        </div>
                    </div>
                </MobileCardHeader>

                <MobileCardBody>
                    <div className='test-lg'>
                        Thank you for your vaccine purchase!
                    </div>
                    <div className='underline mb-5'>
                        Please show this receipt as proof of purchase.
                    </div>
                    <div className='mb-5'>
                        <div className='flex'>
                            <div className='font-bold'>Billing Name:</div>
                            <div className='ml-3'>{billingState.first_name + " " + billingState.last_name}</div>
                        </div>
                        <div className='flex'>
                            <div className='font-bold'>Address Line 1:</div>
                            <div className='ml-3'>{billingState.address_line_1}</div>
                        </div>
                        <div className='flex'>
                        <div className='font-bold'>Address Line 2:</div>
                            <div className='ml-3'>{billingState.address_line_2}</div>
                        </div>
                        <div className='flex'>
                            <div className='font-bold'>City:</div>
                            <div className='ml-3'>{billingState.city}</div>
                        </div>
                        <div className='flex'>
                            <div className='font-bold'>Zip:</div>
                            <div className='ml-3'>{billingState.zip}</div>
                        </div>
                        <div className='flex'>
                            <div className='font-bold'>Billing Email:</div>
                            <div className='ml-3'>{billingState.billing_email}</div>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <div className='flex mb-1'>
                            <div className='font-bold'>Purchased Doses:</div>
                        </div>
                        <div className='flex ml-0 sm:ml-3'>
                            <div className='font-bold'>Fluzone Quadrivalent:</div>
                            <div className='ml-3'>{
                                patients.reduce((value, patient) => {
                                    if (patient.dose === 'Fluzone Quadrivalent') {
                                        return(value + 1);
                                    }
                                    return(value);
                                }, 0)
                            }</div>
                        </div>
                        <div className='flex ml-0 sm:ml-3'>
                            <div className='font-bold'>Fluzone High-Dose:</div>
                            <div className='ml-3'>{
                                patients.reduce((value, patient) => {
                                    if (patient.dose === 'Fluzone High-Dose (65+ years only)') {
                                        return(value + 1);
                                    }
                                    return(value);
                                }, 0)
                            }</div>
                        </div>
                        <div className='flex mt-1'>
                            <div className='font-bold'>Total Payment Received:</div>
                            <div className='ml-3'>{formatter.format(paymentAmount)}</div>
                        </div>
                    </div>
                </MobileCardBody>

            </MobileCard>
        </MobileContainer>
    </>);
}

export { ReceiptComponent };