import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationState } from './models/ApplicationState';
import { BillingInfo } from './models/BillingInfo';
import { Patient } from './components/PatientInfo';
import { PatientInfoComponent } from './components/PatientInfo';
import { BillingInfoComponent } from './components/BillingInfo';
import { CheckoutComponent } from './components/Checkout';
import { ReceiptComponent } from './components/Receipt';

export default function App() {

  // URL Parameters
  const { location } = useParams();

  // Application States
  const [applicationState, setApplicationState] = useState<ApplicationState>(ApplicationState.PATIENT_INFO);
  const [billingState, setBillingState] = useState<BillingInfo>({} as BillingInfo);
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [patients, setPatients] = useState<Patient[]>([{} as Patient]);

  switch(applicationState) {
    case ApplicationState.PATIENT_INFO:
      return(<>
        <PatientInfoComponent
          patients={patients}
          setPatients={setPatients}
          setPaymentAmount={setPaymentAmount}
          setApplicationState={setApplicationState}
        />
      </>);
    case ApplicationState.BILLING_INFO:
      return(<>
        <BillingInfoComponent
          billingState={billingState}
          setBillingState={setBillingState}
          setApplicationState={setApplicationState}
        />
      </>);
    case ApplicationState.CHECKOUT:
      return(<>
        <CheckoutComponent
          billingState={billingState}
          setBillingState={setBillingState}
          paymentAmount={paymentAmount}
          patients={patients}
          setApplicationState={setApplicationState}
        />
      </>);
    case ApplicationState.RECEIPT:
      return(
        <ReceiptComponent 
          patients={patients}
          paymentAmount={paymentAmount}
          billingState={billingState}
        />
      );
  }
}
